// Translates 'a' to '🇦', 'b' to '🇧' and so on.
function letterToLetterEmoji(letter) {
    return String.fromCodePoint(letter.toLowerCase().charCodeAt(0) + 127365);
}
// Translates 'pl' to 'PL', 'en-US' to 'US' and so on.
function countryCodeToCountry(countryCode) {
    var country = countryCode.split('-').pop();
    return country.toUpperCase();
}
// Translates 'pl-PL' to 🇵🇱 and so on.
export default function countryCodeToFlagEmoji(string) {
    if (!string) {
        return null;
    }
    return Array.from(countryCodeToCountry(string)).map(letterToLetterEmoji).join('');
}
